export const StarFilled = ({ fill, size }: { fill?: string; size?: string }) => (
  <svg
    width={size ?? '18'}
    height={size ?? '17'}
    viewBox="0 0 18 17"
    fill={fill ?? '#D66F3D'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.34687 16.7617L4.76875 10.6148L0 6.48047L6.3 5.93359L8.75 0.136719L11.2 5.93359L17.5 6.48047L12.7312 10.6148L14.1531 16.7617L8.75 13.5023L3.34687 16.7617Z" />
  </svg>
)

export const StarEmpty = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.47698 15.2041L5.49946 10.7839L5.60122 10.3439L5.26005 10.0482L1.83003 7.07443L6.36486 6.68078L6.81495 6.64171L6.99083 6.22557L8.75 2.06325L10.5092 6.22557L10.685 6.64171L11.1351 6.68078L15.67 7.07443L12.24 10.0482L11.8988 10.3439L12.0005 10.7839L13.023 15.2041L9.1374 12.8601L8.75 12.6264L8.3626 12.8601L4.47698 15.2041Z"
      stroke="#D66F3D"
      stroke-width="1.5"
    />
  </svg>
)
