import { Box, Image as MantineImage, createStyles } from '@mantine/core'
import Image from 'next/image'

import { getImageUrl } from '~/components/shared/image/helpers'

import EmptyImage from 'public/images/cover/empty.jpg'

const useStyle = createStyles(() => ({
  imageContainer: {
    width: '100%',
    height: '100%',
  },
}))

interface GuideCardImageProps {
  src: string
  imageHeight: number
  imageWidth: number
}
export const GuideCardImage: React.FC<GuideCardImageProps> = ({ src, imageHeight, imageWidth }) => {
  const { classes } = useStyle()

  return (
    <MantineImage
      width="100%"
      height="100%"
      fit="cover"
      alt="guide-image"
      withPlaceholder
      placeholder={
        <Box
          w={imageWidth}
          h={imageHeight}
          sx={{ overflow: 'hidden' }}
        >
          <Image
            width={imageWidth}
            height={imageHeight}
            src={EmptyImage}
            alt="placeholder"
            style={{
              maxWidth: '100%',
              height: '100%',
            }}
          />
        </Box>
      }
      src={getImageUrl({ src, width: imageWidth })}
      classNames={{
        root: classes.imageContainer,
        figure: classes.imageContainer,
        imageWrapper: classes.imageContainer,
      }}
    />
  )
}
